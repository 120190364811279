<template>
  <div class="col-sm-6 col-md-4">
    <div class="thumbnail">
      <Image :src="job.imagen" />
      <div class="caption">
        <h3>
          <span class="vertical-date"> {{ job.puesto }} <small></small> </span>
        </h3>
        <p>
          <span class="vertical-date">
            <small>{{ job.horario }} </small>
          </span>
        </p>
        <dl class="">
          <dt>Estado</dt>
          <dd>
            {{ job.estado }}
          </dd>
          <dt>Zona</dt>
          <dd>
            {{ job.zona }}
          </dd>
        </dl>
        <p class="thumbnail__footer">
          <router-link
            class="btn btn-primary thumbnail__footer--btn"
            role="button"
            :to="{ name: 'jobsdetail', params: { jobId: job.id } }"
            >Ver más</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const Image = defineAsyncComponent(() => import("./Image.vue"));
export default {
  name: "JobThumbnail",
  props: {
    job: {
      type: Object,
      default: function() {
        return {
          estado: "",
          zona: "",
          puesto: "",
          salario: "",
          horario: "",
          prestaciones: "",
          imagen: "",
          src: null,
          id: "",
        };
      },
    },
  },
  components: {
    Image,
  },
};
</script>
<style scoped>
.thumbnail {
  min-height: 480px;
  position: relative;
}

.thumbnail__footer--btn {
  position: absolute;
  bottom: 10px;
}
</style>
