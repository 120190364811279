<template>
  <section id="jobs" class="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="navy-line"></div>
          <h1>Bolsa de empleo</h1>
          <p>
            El trabajo que siempre has búscado.
          </p>
        </div>
      </div>
    </div>

    <div class="container">
      <RequestLoader v-if="loading" />
      <div class="row" v-if="!loading">
        <ProgressBar :progress="progress" />
        <JobThumbnail v-for="(job, i) in jobs" :job="job" :key="i" />
      </div>
    </div>
  </section>
</template>

<script>
import firebase from "../Firebase";
import RequestLoader from "../components/RequestLoader.vue";
import ProgressBar from "../components/ProgressBar.vue";
import JobThumbnail from "../components/JobThumbnail.vue";
export default {
  name: "LayoutJobs",
  data() {
    return {
      loading: false,
      jobs: [],
      count: 0,
    };
  },
  computed: {
    progress: function() {
      return (100 / this.count) * this.jobs.length;
    },
  },
  created() {
    this.loading = true;
    var db = firebase.firestore();
    db.collection("jobs")
      .get()
      .then((querySnapshot) => {
        this.count = querySnapshot.size;

        querySnapshot.forEach((doc) => {
          
          var oDoc = doc.data();
          oDoc.id = doc.id;
          this.jobs.push(oDoc);
        });

        this.loading = false;
      });
  },
  components: {
    RequestLoader,
    ProgressBar,
    JobThumbnail,
  },
};
</script>

<style scoped>
.navy-line {
  margin-top: 0;
}
</style>
