<template>
  <div v-if="progress < 100" class="progress">
    <div
      class="progress-bar progress-bar-primary progress-bar-striped active"
      role="progressbar"
      :aria-valuenow="progress"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="`width: ${progress}%`"
    >
      <span class="sr-only">{{ progress }}% Complete</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style>
.progress-bar-primary {
  background-color: var(--accyon-carousel-color-secondary);
}
</style>
